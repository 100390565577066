* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.Background {
  height: 100%;
  width: 100%;
  position: relative;
}
