.main {
    width: 100vw;
    height: 100vh;
    position: absolute;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    left: 0;
    top: 0;
}

.landing {
    height: 95%;
    width: 100%;
    position: relative;
}

.landingTop {
    position: absolute;
    top: 3.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.landingTop h2 {
    font-size: calc(10px + 1vw);
}
.landingTop h3 {
    font-size: calc(10px + .7vw);
}

.landingCenter {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.landingCenter h1 {
    font-size: calc(15px + 5vw);
}

.landingCenter h3 {
    font-size: calc(10px + 2vw);
}

.midContent {
    position: relative;
    height: auto;
    width: 100%;
    color: black;
}

.centerContentChild {
    position: absolute;
    height: 100%;
    max-height: auto;
    width: 70%;
    max-width: 1200px;
}

.centerRightContent {
    right: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: rgba(55, 55, 55, .95); 
    backdrop-filter: blur(5px); 
    display: flex;
}

.centerLeftContent {
    left: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: rgba(55, 55, 55, .95); 
    backdrop-filter: blur(5px); 
    display: flex;
}

.centerContentTextArea {
    text-align: center;
    height: 100%;
    width: 90%;
    margin: 0% 5% 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.centerContentTextArea h1 {
    font-size:  calc(10px + 1.5vw);
}

.centerContentTextArea ul {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0;
    font-size: calc(5px + .7vw);
}

.centerContentTextArea li {
    margin: 5% 0 5% 0;
}

.content {
    position: relative;
    width: 100%;
    min-height: 600px;
    max-height: 70vh;
    height: auto;
    z-index: 5;
    margin-bottom: 20%;
}

.midContent {
    width: 100%;
    height: auto;
}

.largeContent {
    position: relative;
    width: 100vw;
    margin: auto;
    height: 100vh;
}

.content1 {
    position: relative;

    min-height: 90%;
    width: 90%;
    margin-left: 5%;
    background-color: rgba(55, 55, 55, .95); 
    backdrop-filter: blur(5px); 
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    color: white;
}

.bottomFlexContent {
    position: relative;
    padding: 1%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.contentImageContainer {
    float: left;
    height:100%;
    width:40%;  
    max-width: 500px;
    max-height: 100%;
    overflow: hidden;
}


.contentImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 20px;
}

.contentText {
    float: right;
    max-width: 60%;
    width: 60%;
}

.contentText h3 {
    font-size:  calc(7px + 2.5vw);
}

.contentText p {
    font-size:  calc(7px + .8vw);
}

@media only screen and (max-width: 1100px) {
    .content {
        min-height: 400px;
    }
}

@media only screen and (max-width: 600px) {
    .content {
        min-height: 300px;
    }
}




